import { useAsyncStrapiApi } from "~/uses/useMyFetch";
import {
  getCategoriesQuery,
  getTagsQuery,
} from "~/nuxt_modules/blog/utils/query.utils";
import { useLanguageStore } from "~/store/languageStore";

export const useBlogStore = defineStore("blogStore", () => {
  const categories = ref(null);
  const tags = ref(null);

  const languageStore = useLanguageStore();

  const fetchCategories = async () => {
    // if (null === categories.value) {
    return await useAsyncStrapiApi(
      "fetchBlogCategories",
      `/blog/categories?${getCategoriesQuery(languageStore.language.name)}`,
    ).then(({ data }) => {
      categories.value = data.value.data;
    });
    // }
  };

  const fetchTags = async () => {
    // if (null === tags.value) {
    return await useAsyncStrapiApi(
      "fetchBlogTags",
      `/blog/tags?${getTagsQuery(languageStore.language.name)}`,
    ).then(({ data }) => {
      tags.value = data.value.data;
    });
    // }
  };

  return { categories, tags, fetchCategories, fetchTags };
});
